import React from "react"
import {graphql, StaticQuery, Link} from 'gatsby'

import MainMenu from './main-menu'

const Footer = () => (
    <div>
        <StaticQuery query={query} render={props => 
            <footer className="py-16 border-t border-solid border-l-0 border-r-0 border-b-0 border-gray-200"> 
                <div className="flex flex-wrap justify-between container mx-auto text-black">
                    <div className="w-full lg:w-1/3 px-4 text-center lg:text-left">
                        {props.contentfulSettings.logo && 
                            <Link to="/"><img className="block max-w-sm mb-4 w-full" alt={props.contentfulSettings.logo.description} src={props.contentfulSettings.logo.file.url} /></Link> 
                        }
                        {props.contentfulSettings.footerInfo && <p dangerouslySetInnerHTML={{__html: props.contentfulSettings.footerInfo}} />}
                        <div className="flex flex-wrap items-center">
                            {props.contentfulSettings.accreditation.map((accreditation, i) => <img className="h-24 pr-2" src={accreditation.fixed.src} />)}
                        </div>
                    </div>
                    <div className="flex flex-wrap w-full lg:w-3/5">
                        <div className="w-full md:w-1/3 px-4">
                            <ul className="list-none ml-0 md:mb-0">
                                {props.interior && 
                                    <li>
                                        <Link className="first:pl-0 text-black no-underline hover:text-primary font-bold text-lg" to={`/products/${props.interior.slug}`}>
                                            {props.interior.name}
                                        </Link>
                                        {props.interior.wpChildren && props.interior.wpChildren.nodes.map(item => <li className="mb-0" key={item.slug}>
                                                <Link className="first:pl-0 text-black no-underline hover:text-primary font-light" to={`/products/${props.interior.slug}/${item.slug}`}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )}
                                    </li>
                                }
                                {props.exterior && 
                                    <li>
                                        <Link className="first:pl-0 text-black no-underline hover:text-primary font-bold text-lg" to={`/products/${props.exterior.slug}`}>
                                            {props.exterior.name}
                                        </Link>
                                        {props.exterior.wpChildren && props.exterior.wpChildren.nodes.map(item => <li className="mb-0" key={item.slug}>
                                                <Link className="first:pl-0 text-black no-underline hover:text-primary font-light" to={`/products/${props.exterior.slug}/${item.slug}`}>
                                                    {item.name}
                                                </Link>
                                            </li>
                                        )}
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="w-full md:w-1/3 px-4">
                        <MainMenu menu={props.contentfulMenuLists.menu} />
                        </div>
                        <div className="w-full md:w-1/3 px-4">
                            <div className="flex mb-4">
                                <svg className="mr-2" width="14" height="20" viewBox="0 0 14 20">
                                    <path id="Path_1996" data-name="Path 1996" d="M300-22a7,7,0,0,0-7,7c0,5.25,7,13,7,13s7-7.75,7-13A7,7,0,0,0,300-22Zm0,9.5a2.5,2.5,0,0,1-2.5-2.5,2.5,2.5,0,0,1,2.5-2.5,2.5,2.5,0,0,1,2.5,2.5A2.5,2.5,0,0,1,300-12.5Z" transform="translate(-293 22)"/>
                                </svg>
                                {props.contentfulSettings.address &&<div dangerouslySetInnerHTML={{__html: props.contentfulSettings.address}} />}
                            </div>
                            {props.contentfulSettings.email &&
                                <div className="flex mb-4 items-center">
                                    <svg className="mr-2" width="14" height="9.292" viewBox="0 0 14 9.292">
                                        <g id="Group_4" data-name="Group 4" transform="translate(-321.982 -2909.592)">
                                            <path id="Path_1998" data-name="Path 1998" d="M337.313,2909.592H324.349l6.482,5.315Z" transform="translate(-1.849)"/>
                                            <path id="Path_1999" data-name="Path 1999" d="M328.982,2917.271l-7-5.74v8.868h14v-8.868Z" transform="translate(0 -1.515)"/>
                                        </g>
                                    </svg>
                                    <a href={`mailto:${props.contentfulSettings.email}`}>{props.contentfulSettings.email}</a>
                                </div>
                            }
                            {props.contentfulSettings.phone &&
                            <div className="flex mb-4 items-center">
                                <svg className="mr-2" width="14" height="13.978" viewBox="0 0 14 13.978">
                                    <g id="_2pII7b" data-name="2pII7b" transform="translate(-103 -2900.379)">
                                        <g id="Group_3" data-name="Group 3" transform="translate(103 2900.379)">
                                        <path id="Path_1997" data-name="Path 1997" d="M103,2903.927a3.929,3.929,0,0,1,.285-1.478,3.745,3.745,0,0,1,.641-1.2,2.374,2.374,0,0,1,.58-.515,3,3,0,0,1,1.449-.35.415.415,0,0,1,.392.206c.227.377.46.75.681,1.131.305.525.6,1.058.9,1.581.239.408.236.612-.1.934s-.693.594-1.033.9a4.068,4.068,0,0,0-.4.424.424.424,0,0,0-.018.476c.1.222.224.436.345.649a10.647,10.647,0,0,0,1.636,2.211,8.886,8.886,0,0,0,1.505,1.244c.407.263.827.506,1.241.756a1.09,1.09,0,0,0,.1.053.583.583,0,0,0,.854-.171c.353-.395.7-.792,1.058-1.186a1.626,1.626,0,0,1,.186-.167.5.5,0,0,1,.62-.059l1.561.905c.424.246.846.5,1.271.74a.444.444,0,0,1,.24.424,3.325,3.325,0,0,1-.335,1.422,2.1,2.1,0,0,1-.912.858,5.291,5.291,0,0,1-1.493.573,3.237,3.237,0,0,1-1.509-.034,13.554,13.554,0,0,1-2.841-1.076,12.747,12.747,0,0,1-3.038-2.251,15.673,15.673,0,0,1-1.939-2.251,10.747,10.747,0,0,1-1.352-2.659c-.131-.379-.274-.755-.384-1.14C103.11,2904.564,103.065,2904.243,103,2903.927Z" transform="translate(-103 -2900.379)"/>
                                        </g>
                                    </g>
                                </svg>
                                <a href={`tel:${props.contentfulSettings.phone}`}>{props.contentfulSettings.phone}</a>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </footer>
        } />
    </div>
)

export default Footer

const query = graphql`
{
    contentfulSettings(id: {eq: "c73f9c34-0844-52cd-828a-c8538330c4b5"}) {
        logo {
          description
          file {
            url
          }
        }
        address
        email
        phone
        footerInfo
        accreditation {
            fixed {
                src
            }
        }
        
    }
    contentfulMenuLists(title: {eq: "Main Menu"}) {
        menu {
          title
          link
        }
    }
    exterior: wpCategory(termTaxonomyId: {eq: 32}) {
        slug
        name
        wpChildren {
          nodes {
            slug
            name
          }
        }
    }
    interior: wpCategory(termTaxonomyId: {eq: 42}) {
        slug
        name
        wpChildren {
          nodes {
            slug
            name
          }
        }
    }
}`