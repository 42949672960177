import React from 'react'
import {Link} from 'gatsby'

const MainMenu = ({menu}) => (
    <ul className="list-none ml-0 md:mb-0">
        {menu.map((item) => (
                <li key={item.title}>
                    <Link className="first:pl-0 text-black no-underline hover:text-primary" to={`${item.link}`}>
                        {item.title}
                    </Link>
                </li>
            )
        )}
    </ul>  
)

export default MainMenu

