import React from 'react'
import {graphql, StaticQuery} from 'gatsby'

const Topbar = () => (
  <StaticQuery query={query} render={props => {
    let data = props.contentfulSettings
    return (
      <div className="bg-primary text-white py-2 flex flex-wrap justify-between px-4"> 
          <div className="font-medium text-sm flex flex-wrap">
            {data.openingHours.map((hour, i) => (
              hour && <span className="pr-6" key={i}>{hour}</span>
            ))}
          </div>
          <div className="font-meduim text-sm hidden md:flex flex-wrap">
            {data.email && <span>Email:  
               <a className="text-white hover:opacity-50 duration-300 ml-2" href={`mailto:${data.email}`}>{data.email}</a>
            </span>}
            <span className="block mx-6 w-px h-6 bg-white"></span>
            {data.phone && <span>Tel:  
               <a className="text-white hover:opacity-50 duration-300 ml-2" href={`tel:${data.phone}`}>{data.phone}</a>
            </span>}
          </div>
      </div>
    )
  }} />
)

export default Topbar

const query = graphql`
  {
    contentfulSettings(id: {eq: "c73f9c34-0844-52cd-828a-c8538330c4b5"}) {
      phone
      email
      openingHours
    }
  }
`
