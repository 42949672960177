import React from 'react'
import { v4 as uuidv4 } from 'uuid'

import CategoryItemTopLevel from './Category-item-top-level'

const CategoryListTopLevel = ( {parentUrl, categories} ) => (
  <div className="flex flex-wrap mb-16 md:mb-24 container mx-auto">
    {categories.nodes && categories.nodes.map(category => (
        <CategoryItemTopLevel key={uuidv4()} parentUrl={parentUrl} category={category}/>
    ))} 
    {(categories && !categories.nodes) && categories.map(category => (
        <CategoryItemTopLevel key={uuidv4()} parentUrl={parentUrl} category={category}/>
    ))} 
  </div>
)

export default CategoryListTopLevel