import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
//import Emailer from '../../components/sections/Emailer'
import CategoryTitle from '../../components/categories/Category-title'
import CategoryListTopLevel from '../../components/categories/Categories-list-top-level'
import Topbar from '../../components/sections/Topbar';

export default ({ data: {category} }) => (
  <Layout>
      <CategoryTitle 
        backgroundUrl={category.categoryImage.headerBackground.localFile.childImageSharp.fluid} 
        title={category.name}
      />
      <CategoryListTopLevel categories={category.wpChildren} parentUrl={category.slug}/>
  </Layout>
)


export const query = graphql`
  query($slug: String!) {
    category: wpCategory(slug: {eq: $slug}) {
      name
      slug
      termTaxonomyId
      wpChildren {
        nodes {
          name
          slug
          categoryImage {
            headerBackground {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000, quality: 50) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
      categoryImage {
        headerBackground {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 70) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`