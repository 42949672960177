import React from 'react'
import BackgroundImage from 'gatsby-background-image'

const CategoryTitle = ( {title, backgroundUrl} ) => (
  <BackgroundImage  
    tag="section" 
    className={`w-full flex flex-wrap items-center justify-center px-2 mb-16 md:mb-24`}
    fluid={backgroundUrl} 
  >
    {title && <h1 className="mb-0 text-5xl py-16 md:py-32 text-white">{title}</h1>}
  </BackgroundImage>  
)

export default CategoryTitle