import { Link } from 'gatsby'
import React from 'react'

const BottomBar = () => (
  <div className="bottombar bg-primary">
    <div className="container mx-auto w-full flex flex-wrap justify-between text-right text-white p-4 text-sm">
      <Link to="/terms-and-conditions">
        Terms & Conditions
      </Link>
      <div>
      Website created by <a target="_blank" rel="noreferrer" className="duration-300 hover:opacity-50" href="https://www.pagecreative.co.uk/">Page Creative</a>
      </div>
    </div>
  </div>
)

export default BottomBar