import React from "react"
import {graphql, StaticQuery, Link} from 'gatsby'

import MainMenu from './MainMenu'

const Header = ({ siteTitle }) => (
    <header className="bg-white shadow-lg text-black py-4 relative z-10 px-4">           
      <StaticQuery 
        query={query} 
        render={props => <div className="flex flex-wrap justify-between items-center">
          {props.contentfulSettings.logo ? 
          <Link to="/">
            <img className="block max-w-lg mb-0 w-64 md:w-auto" alt={props.contentfulSettings.logo.description} src={props.contentfulSettings.logo.file.url} />
          </Link> 
          : 'Add Logo Here'}
          <MainMenu menu={props.contentfulMenuLists.menu}/>
          </div>
        } 
      />
    </header>
)

export default Header

const query = graphql`
{
  contentfulSettings(id: {eq: "c73f9c34-0844-52cd-828a-c8538330c4b5"}) {
    logo {
      description
      file {
        url
      }
    }
  }
  contentfulMenuLists(title: {eq: "Main Menu"}) {
    menu {
      title
      link
    }
  } 
}
`