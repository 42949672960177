import React from 'react'
import {graphql, StaticQuery, Link} from 'gatsby'

class MainMenu extends React.Component {
  
    constructor(props) {
        super(props)
        this.state = {
            menuShow: false
        }
    }

    closeMenu() {
        this.setState({
            menuShow: false
        })
    }

    openMenu() {
        this.setState({
            menuShow: true
        })
    }

    showMenu() {
        return this.state.menuShow ? 'menu--active' : 'menu--disabled'
    }

    render () {
        return (
            <div>
                <div onKeyDown={() => this.openMenu()} onClick={() => this.openMenu()} className="btn transition text-black cursor-pointer md:hidden">
                    <svg height="32px" id="Layer_1" enableBackground="new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px">
                        <path className="fill-current" d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2  s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2  S29.104,22,28,22z"/>
                        </svg>
                </div>
                <nav className={`menu ${this.showMenu()} text-white md:text-black flex flex-col items-center text-center md:block transition pt-24 md:pt-0`}>
                    {this.props.menu && this.props.menu.map((item, index) => (
                        <Link className={`${(index === this.props.menu.length - 1) ? 
                            'first:pl-0 md:pl-5' : 'first:pl-0 md:px-5'}
                            py-4 text-xl md:text-base text-white md:text-black hover:font-bold no-underline md:hover:text-primary`} 
                            to={`${item.link}`} 
                            key={item.title}
                        >
                            {item.title}
                        </Link>
                    ))}
                    <div onKeyDown={() => this.closeMenu()} onClick={() => this.closeMenu()} className="btn transition menu__cross text-white cursor-pointer md:hidden">
                        <svg className="w-10" enableBackground="new 0 0 96 96" id="circle_cross" version="1.1" viewBox="0 0 96 96" width="96px" >
                            <path className="fill-current" d="M48,4C23.7,4,4,23.699,4,48s19.7,44,44,44s44-19.699,44-44S72.3,4,48,4z M48,84c-19.882,0-36-16.118-36-36s16.118-36,36-36  s36,16.118,36,36S67.882,84,48,84z"/>
                            <path className="fill-current" d="M53.657,48l8.485-8.485c1.562-1.562,1.562-4.095,0-5.656c-1.562-1.562-4.096-1.562-5.658,0L48,42.343l-8.485-8.484  c-1.562-1.562-4.095-1.562-5.657,0c-1.562,1.562-1.562,4.096,0,5.656L42.343,48l-8.485,8.485c-1.562,1.562-1.562,4.095,0,5.656  c1.562,1.562,4.095,1.562,5.657,0L48,53.657l8.484,8.484c1.562,1.562,4.096,1.562,5.658,0c1.562-1.562,1.562-4.096,0-5.656  L53.657,48z"/>
                        </svg>
                    </div>
                </nav>  
            </div>
        )
    }
}

export default MainMenu;


