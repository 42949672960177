import React from 'react'

import Header from './header/Header'
import Footer from './footer/Footer'
import Topbar from './sections/Topbar'
import BottomBar from './bottom-bar/BottomBar'

const Layout = ({ children }) =>  (
  <div>
    <Topbar />
    <Header />
    {children}
    <Footer />
    <BottomBar />
  </div>
)

export default Layout
